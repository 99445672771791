import { stringify } from "query-string"
import { dhFoods } from "./api"

const endpoint = "/contract"

export const getAllServers = async queries => {
  const query = stringify(queries, {
    skipEmptyString: true,
    skipNull: true
  })

  const response = await dhFoods.get(`${endpoint}/all?${query}`)

  return response.data
}

export const getServer = async (contractId) => {
  const response = await dhFoods.get(`${endpoint}/${contractId}`)

  return response.data
}

export const activateService = async (contractId, status) => {
  const response = await dhFoods.post(`${endpoint}/activation/${contractId}?status=${status}`)

  return response.data
}

export const getServiceActivation = async contractId => {
  const response = await dhFoods.get(`${endpoint}/activation/${contractId}`)

  return response.data
}

export const editSecurityKeys = async (contractId, data) => {
  const response = await dhFoods.post(`${endpoint}/securitykeys/${contractId}`, data)

  return response.data
}