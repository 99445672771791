import clsx from "clsx"
import React from "react"

import classes from "./Button.module.scss"

const Button = props => {
  const {
    variant = "solid",
    className = "",
    color = "primary",
    children,
    loading = false,
    disabled = false,
    onClick = () => { },
    fullWidth = false,
    ...otherProps
  } = props

  return (
    <button
      disabled={disabled}
      onClick={() => !loading && onClick()}
      className={clsx(className,
        variant === "solid" && classes[`solid-${color}`],
        variant === "outline" && classes[`outline-${color}`],
        variant === "pill" && classes[`pill-${color}`],
        variant === "pill-outline" && classes[`pill-outline-${color}`],
        disabled && classes["disabled"],
        fullWidth && classes["full-width"]
      )}
      {...otherProps}
    >
      {loading ?
        <i className="fa fa-spinner fa-spin" /> :
        children
      }
    </button>
  )
}

export default Button