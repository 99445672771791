import React from "react"

import classes from "./DpTimeInputs.module.scss"

const DpTimeInputs = props => {
  const {
    rangeTimePicker,
    timeRangeStep,
    timeRange,
    setTimeRange,
    selectedTime,
    setSelectedTime
  } = props

  const handleChangeHours = value => {
    if (value > 23 || value.length > 2) return

    setSelectedTime(prev => ({
      ...prev, hours: value
    }))
  }

  const handleChangeMinutes = value => {
    if (value >= 60 || value.length > 2) return

    setSelectedTime(prev => ({
      ...prev, minutes: value
    }))
  }

  const handleFixHours = (value) => {
    if (value < 10 && value[0] !== "0") {
      setSelectedTime(prev => ({
        ...prev,
        hours: `0${prev.hours}`
      }))
    }
  }

  const handleFixMinutes = (value) => {
    if ((value < 10 && value[0] !== "0") ||
      value === "0"
    ) {
      setSelectedTime(prev => ({
        ...prev,
        minutes: `0${prev.minutes}`
      }))
    }
  }

  const handleChangeHoursRange = value => {
    if (value > 24 || value.length > 2) return

    setTimeRange(prev => {
      const array = [...prev]

      array[timeRangeIndex].hours = value
      return array
    })
  }

  const handleChangeMinutesRange = value => {
    if (value >= 60 ||
      value.length > 2 ||
      Number(timeRange[timeRangeIndex].hours) === 24
    ) return

    setTimeRange(prev => {
      const array = [...prev]

      array[timeRangeIndex].minutes = value
      return array
    })
  }

  const handleFixHoursRange = (value) => {
    if (value < 10 &&
      value[0] !== "0"
    ) {
      setTimeRange(prev => {
        const array = [...prev]

        array[timeRangeIndex].hours = `0${value}`
        return array
      })
    }
  }

  const handleFixMinutesRange = (value) => {
    if ((value < 10 && value[0] !== "0") ||
      value === "0"
    ) {
      setTimeRange(prev => {
        const array = [...prev]
        array[timeRangeIndex].minutes = `0${value}`
        return array
      })
    }
  }

  const timeRangeIndex = timeRangeStep === "start" ? 0 : 1
  return (
    <div className={classes["row"]}>
      <input
        type="number"
        onChange={e => rangeTimePicker ?
          handleChangeHoursRange(e.target.value) :
          handleChangeHours(e.target.value)
        }
        onBlur={e => rangeTimePicker ?
          handleFixHoursRange(e.target.value) :
          handleFixHours(e.target.value)
        }
        value={rangeTimePicker ?
          timeRange[timeRangeIndex].hours :
          selectedTime.hours
        }
        className={classes["input"]}
        maxLength={2}
      />
      <input
        onChange={e => rangeTimePicker ?
          handleChangeMinutesRange(e.target.value) :
          handleChangeMinutes(e.target.value)
        }
        value={rangeTimePicker ?
          timeRange[timeRangeIndex].minutes :
          selectedTime.minutes
        }
        onBlur={e => rangeTimePicker ?
          handleFixMinutesRange(e.target.value) :
          handleFixMinutes(e.target.value)
        }
        type="number"
        className={classes["input"]}
        maxLength={2}
      />
    </div>
  )
}

export default DpTimeInputs