import React from "react"
import clsx from "clsx"

import classes from "./PreLoader.module.scss"

const PreLoader = () => (
    <div className={classes["container"]}>
        <div className={classes["spinner-8"]}>
            <div className={clsx(classes["ms-circle1"],
                classes["ms-child"])}
            />
            <div className={clsx(classes["ms-circle2"],
                classes["ms-child"])}
            />
            <div className={clsx(classes["ms-circle3"],
                classes["ms-child"])}
            />
            <div className={clsx(classes["ms-circle4"],
                classes["ms-child"])}
            />
            <div className={clsx(classes["ms-circle5"],
                classes["ms-child"])}
            />
            <div className={clsx(classes["ms-circle6"],
                classes["ms-child"])}
            />
            <div className={clsx(classes["ms-circle7"],
                classes["ms-child"])}
            />
            <div className={clsx(classes["ms-circle8"],
                classes["ms-child"])}
            />
            <div className={clsx(classes["ms-circle9"],
                classes["ms-child"])}
            />
            <div className={clsx(classes["ms-circle10"],
                classes["ms-child"])}
            />
            <div className={clsx(classes["ms-circle11"],
                classes["ms-child"])}
            />
            <div className={clsx(classes["ms-circle12"],
                classes["ms-child"])}
            />
        </div>
    </div>
)

export default PreLoader