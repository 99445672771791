import { dhFoods } from "./api"

const endpoint = "/setting"

export const getSettings = async () => {
  const response = await dhFoods.get(`${endpoint}`)

  return response.data
}

export const editSettings = async data => {
  const response = await dhFoods.patch(`${endpoint}`, data)

  return response.data
}