import React from "react"

const AddProduct = React.lazy(() => import("../pages/add-product"))
const EditProduct = React.lazy(() => import("../pages/edit-product"))
const Products = React.lazy(() => import("../pages/products"))
const Users = React.lazy(() => import("../pages/users"))
const Services = React.lazy(() => import("../pages/services"))
const Service = React.lazy(() => import("../pages/service"))
const Bills = React.lazy(() => import("../pages/bills"))
const AppVersion = React.lazy(() => import("../pages/app-version"))

const privateRoutes = [{
  path: "/products",
  component: Products,
  exact: true,
  allowedRoles: ["subscribeAdmin"]
}, {
  path: "/add-product",
  component: AddProduct,
  exact: true,
  allowedRoles: ["subscribeAdmin"]
}, {
  path: "/bills/:contractId",
  component: Bills,
  allowedRoles: ["subscribeAdmin"]
}, {
  path: "/edit-product/:productId",
  component: EditProduct,
  exact: true,
  allowedRoles: ["subscribeAdmin"]
}, {
  path: "/users",
  component: Users,
  exact: true,
  allowedRoles: ["subscribeAdmin"]
}, {
  path: "/services/:accountId",
  component: Services,
  exact: true,
  allowedRoles: ["subscribeAdmin"]
}, {
  path: "/service/:serverId",
  component: Service,
  exact: true,
  allowedRoles: ["subscribeAdmin"]
}, {
  path: "/app-version",
  component: AppVersion,
  exact: true,
  allowedRoles: ["subscribeAdmin"]
}]

export default privateRoutes