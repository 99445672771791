import React, { useState, Fragment, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import clsx from "clsx"

import { fetchSystemSettings } from "@redux"
import SideNavigation from "./SideNavigation"
import TopNavigation from "./TopNavigation"
import classes from "./index.module.scss"

const Container = props => {
  const { children } = props
  const history = useHistory()

  const dispatch = useDispatch()

  const [openSidebar, setOpenSidebar] = useState(window.innerWidth >= 992)

  useEffect(() => {
    dispatch(
      fetchSystemSettings()
    )

    history.listen((location, action) => {
      if (action !== "PUSH") return
      window.scrollTo({ top: 0, left: 0 })
    })
  }, [])

  return (
    <Fragment>
      <TopNavigation
        setOpenSidebar={setOpenSidebar}
      />
      <div className={classes["container"]}>
        <div className={clsx(classes["wrapper"],
          openSidebar && classes["open"]
        )}>
          {children}
        </div>
      </div>
      <SideNavigation
        openSidebar={openSidebar}
      />
    </Fragment>
  )
}

export default Container