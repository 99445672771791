const navItems = {
  subscribeAdmin: [{
    path: "/products",
    icon: <i className="fa fa-list" />,
    title: "Products"
  }, {
    path: "/add-product",
    icon: <i className="fa fa-plus" />,
    title: "Add Product"
  }, {
    path: "/users",
    icon: <i className="fa fa-users" />,
    title: "Users"
  }, {
    path: "/app-version",
    icon: <i className="fa fa-android" />,
    title: "App version"
  }]
}

export default navItems