import React from "react"
import {
  OverlayTrigger,
  Tooltip as RbTooltip
} from "react-bootstrap"

const Tooltip = props => {
  const {
    placement = "bottom",
    title,
    children,
    ...otherProps
  } = props

  return (
    <OverlayTrigger
      placement={placement}
      {...otherProps}
      overlay={({ ...props }) => (title ?
        <RbTooltip {...props}>
          {title}
        </RbTooltip> :
        <span />
      )}
    >
      {children}
    </OverlayTrigger>
  )
}

export default Tooltip