import { stringify } from "query-string"
import { dhFoodsAuth } from "./api"

const endpoint = "/account"

export const getOtpForMobile = async data => {
  const response = await dhFoodsAuth.post(`${endpoint}/otp/mobilePhone`, data)

  return response.data
}

export const confirmOtp = async data => {
  const response = await dhFoodsAuth.post(`${endpoint}/otp/confirm`, data)

  return response.data
}

export const signin = async (data, type = "mobilePhone") => {
  const response = await dhFoodsAuth.post(`${endpoint}/login/${type}`, data)

  return response.data
}

export const getUserAccountInfo = async (queries) => {
  const query = stringify(queries, {
    skipEmptyString: true,
    skipNull: true
  })

  const response = await dhFoodsAuth.get(`${endpoint}/info?${query}`)

  return response.data
}

export const editUserAccountInfo = async () => {
  const response = await dhFoodsAuth.patch(`${endpoint}/info`)

  return response.data
}

export const getAllUserAccounts = async mobile => {
  const response = await dhFoodsAuth.get(`${endpoint}/manage/info/${mobile}`)

  return response.data
}

export const changeUserRole = async (mobile, role) => {
  const response = await dhFoodsAuth.patch(`${endpoint}/manage/changeRole/${mobile}/${role}`)

  return response.data
}
export const getUserList = async queries => {
  const query = stringify(queries, { skipEmptyString: true, skipNull: true })

  const response = await dhFoodsAuth.get(`${endpoint}/userList?${query}`)

  return response.data
}

export const getAccountBalanceReport = async () => {
  const response = await dhFoodsAuth.get(`${endpoint}/report/balance`)

  return response.data
}