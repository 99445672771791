import React from "react"
import clsx from "clsx"
import { Link, useHistory, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"

import { logout } from "utils"
import navItems from "../constants/navItems"
import classes from "./SideNavigation.module.scss"

const SideNavigation = (props) => {
  const { openSidebar } = props

  const { role } = useSelector(state =>
    state.userAccountInfo
  )

  const location = useLocation()
  const history = useHistory()

  if (!role) return null

  return (
    <div
      className={clsx(classes["container"],
        openSidebar && classes["open"]
      )}
    >
      {navItems[role].map(navItem => (
        <Link
          key={navItem.path}
          className={clsx(classes["tab"],
            navItem.path === location.pathname && classes["active"])}
          to={navItem.path}
        >
          {navItem.icon}
          {navItem.title}
        </Link>
      ))}
      <div
        className={classes["tab"]}
        onClick={() => {
          logout()
          history.push("/")
        }}
      >
        <i className="fa fa-signout" />
        Logout
      </div>
    </div>
  )
}

export default SideNavigation