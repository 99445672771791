import * as types from "../types/systemSettings"

const initialState = {
  posSystem: {
    versionControl: {
      androidVersion: "",
      iosVersion: "",
      appStoreUrl: "",
      playstoreUrl: "",
      forceVersionIos: "",
      forceVersionAndroid: ""
    }
  },
  billplzTransactionLimitation: "ّ"
}

const systemSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SYSTEM_SETTINGS:
      return { ...action.data }
    case types.UPDATE_SYSTEM_SETTINGS:
      return {
        ...state,
        ...action.newSystemSettings
      }
    default:
      return state
  }
}

export default systemSettingsReducer