import React, { Fragment } from "react"

import classes from "./DpMonthHeader.module.scss"

const monthsName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
const daysName = ["Sun", "Mon", "Tue", "Wen", "Thu", "Fri", "Sat"]

const DpMonthHeader = props => {
  const {
    minDate,
    maxDate,
    shownDate,
    setShownDate,
    setContent
  } = props

  const handleChangeMonth = action => {
    const newDate = new Date(shownDate)
    if (shownDate.getMonth() === 0 ||
      shownDate.getMonth() === 11
    ) {
      newDate.setMonth(
        newDate.getMonth() + action
      )
      setShownDate(newDate)
    } else {
      newDate.setMonth(
        newDate.getMonth() + action
      )
      setShownDate(newDate)
    }
  }

  const firstDayInShownDateMonth = new Date(shownDate)
  firstDayInShownDateMonth.setDate(1)
  firstDayInShownDateMonth.setHours(0, 0, 0, 0)
  const firstDayInmaxDateMonth = new Date(maxDate)
  firstDayInmaxDateMonth.setDate(1)
  firstDayInmaxDateMonth.setHours(0, 0, 0, 0)
  const firstDayInminDateMonth = new Date(minDate)
  firstDayInminDateMonth.setDate(1)
  firstDayInminDateMonth.setHours(0, 0, 0, 0)
  const hideRightChevron =
    Number(firstDayInShownDateMonth) >= Number(firstDayInmaxDateMonth)
  const hideLeftChevron =
    Number(firstDayInShownDateMonth) <= Number(firstDayInminDateMonth)

  return (
    <Fragment>
      <div className={classes["month"]}>
        <button
          className={classes["chevron"]}
          onClick={() => handleChangeMonth(-1)}
        >
          {
            !hideLeftChevron &&
            <i className="fa fa-chevron-left" />
          }
        </button>
        <div
          className={classes["current-month"]}
          onClick={() => setContent("months")}
        >
          {shownDate.getFullYear()}{" "}
          {monthsName[shownDate.getMonth()]}
        </div>
        <button
          className={classes["chevron"]}
          onClick={() => handleChangeMonth(1)}
        >
          {
            !hideRightChevron &&
            <i className="fa fa-chevron-right" />
          }
        </button>
      </div>
      <div className={classes["days-name"]}>
        {daysName.map(dayName =>
          <div
            key={dayName}
            className={classes["day-name"]}
          >
            {dayName}
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default DpMonthHeader