import React, { useState } from "react"

import classes from "./DpFooter.module.scss"

const DpFooter = props => {
  const {
    timeRange,
    setTimeRange,
    rangeTimePicker,
    showTimeSelect,
    timeRangeStep,
    setTimeRangeStep,
    selectedDate,
    selectedTime,
    setOpenDatePicker,
    timePicker,
    onChange,
    selectedRange,
    rangeSelect
  } = props

  const [error, setError] = useState("")

  const handleConfirmRange = () => {
    const resultRange = [...selectedRange]

    if (!resultRange[1]) {
      resultRange[1] = new Date(resultRange[0])
    }

    resultRange[0].setHours(0, 0, 0)
    resultRange[1].setHours(23, 59, 59)

    onChange(resultRange)
    setOpenDatePicker(false)
  }

  const handleConfirmDate = () => {
    const resultDate = new Date(selectedDate)
    showTimeSelect && resultDate.setHours(
      selectedTime.hours,
      selectedTime.minutes,
      0
    )

    timePicker ?
      onChange(`${selectedTime.hours}:${selectedTime.minutes}:00`) :
      onChange(resultDate)

    setOpenDatePicker(false)
  }

  const handleTimeRange = () => {
    if (timeRangeStep === "start") {

      !timeRange[1] && setTimeRange(prev => {
        const array = [...prev]
        array[1] = {
          hours: "00",
          minutes: "00"
        }
        return array
      })

      return setTimeRangeStep("end")
    }

    if ((Number(timeRange[0].hours) > Number(timeRange[1].hours))
    ) return setError("End time is before start time")
    else if (
      Number(timeRange[0].hours) === Number(timeRange[1].hours) &&
      Number(timeRange[0].minutes) > Number(timeRange[1].minutes)
    ) return setError("End time is before start time")

    onChange([`${timeRange[0].hours}:${timeRange[0].minutes}:00`,
    `${timeRange[1].hours}:${timeRange[1].minutes}:00`
    ])

    setOpenDatePicker(false)
  }

  const handleCancel = () => {
    setOpenDatePicker(false)
  }

  return (
    <div className={classes["footer"]}>
      {
        !!error &&
        <div className={classes["error"]}>
          *{error}
        </div>
      }
      {rangeTimePicker ?
        <button
          onClick={handleTimeRange}
        >
          {timeRangeStep === "start" ? "Next" : "Confirm"}
        </button> :
        <button
          onClick={rangeSelect ?
            handleConfirmRange :
            handleConfirmDate
          }
        >
          Confirm
        </button>
      }
      <button
        onClick={handleCancel}
      >
        Close
      </button>
    </div>
  )
}

export default DpFooter