import React, { useState, useLayoutEffect } from "react"
import { Modal } from "react-bootstrap"

import DpDates from "./DpDates"
import DpMonths from "./DpMonths"
import DpYears from "./DpYears"
import DpTimePicker from "./DpTimePicker"
import DpFooter from "./DpFooter"
import DpHeader from "./DpHeader"
import classes from "./index.module.scss"

const initialFormState = {
  hours: 0,
  minutes: 0
}

const DatePicker = props => {
  const {
    openDatePicker = false,
    setOpenDatePicker = () => { },
    disabled = false,
    minDate,
    maxDate,
    showTimeSelect = false,
    rangeTimePicker = false,
    timePicker = false,
    rangeSelect = false,
    range = [],
    value,
    onChange,
  } = props

  const dt = new Date()
  const initialDateState = (timePicker && !!value) ?
    new Date(`2022 ${value}`) : dt
  const initialContentState = rangeTimePicker ?
    "time" : timePicker ? "time" : "days"

  const [selectedRange, setSelectedRange] = useState([])
  const [shownDate, setShownDate] = useState(initialDateState)
  const [selectedDate, setSelectedDate] = useState(initialDateState)
  const [selectedTime, setSelectedTime] = useState(initialFormState)
  const [content, setContent] = useState(initialContentState)
  const [timeRangeStep, setTimeRangeStep] = useState("start")
  const [timeRange, setTimeRange] = useState([{ hours: "00", minutes: "00" }])

  const handleExited = () => {
    if (value) {
      setSelectedDate(timePicker ?
        new Date(`2022 ${value}`) : value
      )
    } else {
      setShownDate(dt)
      setSelectedDate(dt)
    }

    setTimeRangeStep("start")
    setTimeRange([{ hours: "00", minutes: "00" }])
    setSelectedRange([])
    setContent(rangeTimePicker ?
      "time" : timePicker ? "time" : "days"
    )
  }

  useLayoutEffect(() => {
    if (!value ||
      !openDatePicker
    ) return

    if (timePicker) {
      setShownDate(new Date(`2022 ${value}`))
      setSelectedDate(new Date(`2022 ${value}`))
      return
    }

    setShownDate(value)
    setSelectedDate(value)
  }, [value, openDatePicker])

  useLayoutEffect(() => {
    if (
      !rangeSelect ||
      !openDatePicker
    ) return

    setSelectedRange(range)
    !!range[0] && setShownDate(range[0])
  }, [openDatePicker])

  useLayoutEffect(() => {
    if (!rangeTimePicker) return
    if (!range?.[0] || !openDatePicker) return

    const startTimeRangeArray = range[0].split(":")
    const endTimeRangeArray = range[1].split(":")

    setTimeRange([{
      hours: startTimeRangeArray[0],
      minutes: startTimeRangeArray[1]
    }, {
      hours: endTimeRangeArray[0],
      minutes: endTimeRangeArray[1]
    }])
  }, [openDatePicker])

  const selectContent = () => {
    switch (content) {
      case "days":
        return (
          <DpDates
            minDate={minDate}
            maxDate={maxDate}
            rangeSelect={rangeSelect}
            selectedRange={selectedRange}
            setSelectedRange={setSelectedRange}
            showTimeSelect={showTimeSelect}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            shownDate={shownDate}
            setShownDate={setShownDate}
            setContent={setContent}
          />
        )
      case "months":
        return (
          <DpMonths
            minDate={minDate}
            maxDate={maxDate}
            shownDate={shownDate}
            setShownDate={setShownDate}
            setContent={setContent}
          />
        )
      case "years":
        return (
          <DpYears
            minDate={minDate}
            maxDate={maxDate}
            shownDate={shownDate}
            setShownDate={setShownDate}
            setContent={setContent}
          />
        )
      case "time":
        return (
          <DpTimePicker
            timeRange={timeRange}
            setTimeRange={setTimeRange}
            rangeTimePicker={rangeTimePicker}
            timeRangeStep={timeRangeStep}
            setTimeRangeStep={setTimeRangeStep}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
            setContent={setContent}
            timePicker={timePicker}
          />
        )
      default: return null
    }
  }

  return (
    <Modal
      show={!disabled && openDatePicker}
      onHide={() => setOpenDatePicker(false)}
      dialogClassName={classes["dialog"]}
      contentClassName={classes["content"]}
      onExited={handleExited}
      centered
    >
      <DpHeader
        shownDate={shownDate}
        rangeSelect={rangeSelect}
        selectedRange={selectedRange}
        timePicker={timePicker}
        selectedDate={selectedDate}
        setContent={setContent}
        showTimeSelect={showTimeSelect}
        rangeTimePicker={rangeTimePicker}
        timeRange={timeRange}
      />
      <div className={classes["body"]}>
        {selectContent()}
      </div>
      <DpFooter
        timeRange={timeRange}
        setTimeRange={setTimeRange}
        rangeTimePicker={rangeTimePicker}
        timeRangeStep={timeRangeStep}
        setTimeRangeStep={setTimeRangeStep}
        showTimeSelect={showTimeSelect}
        selectedDate={selectedDate}
        selectedTime={selectedTime}
        setOpenDatePicker={setOpenDatePicker}
        timePicker={timePicker}
        onChange={onChange}
        selectedRange={selectedRange}
        rangeSelect={rangeSelect}
      />
    </Modal>
  )
}

export default DatePicker