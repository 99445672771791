import React, { useState } from "react"
import clsx from "clsx"
import { Form } from "react-bootstrap"

import { CountDownTimer } from "components"
import classes from "./TextField.module.scss"

const TextField = props => {
  const {
    className = "",
    value = "",
    label = "",
    error,
    initialTime,
    startIcon,
    withoutSpace = false,
    min,
    max,
    isAlphaNumberic = false,
    numberType = "float",
    type = "text",
    maxLength = -1,
    isPassword = false,
    onTimeout = () => { },
    onChange = () => { },
    ...otherProps
  } = props

  const [showPassword, setShowPassword] = useState(false)

  const handleKeyDown = e => {
    if (
      type === "number" &&
      numberType === "int" &&
      (e.keyCode === 190 || e.keyCode === 110)
    ) e.preventDefault()

    if (
      type === "number" &&
      min === 0 &&
      (e.keyCode === 45 || e.keyCode === 109)
    ) e.preventDefault()

    if (
      type === "number" &&
      (e.keyCode === 43 || e.keyCode === 107)
    ) e.preventDefault()
  }

  const handleChange = e => {
    if (type === "number") {
      if (max !== undefined && e.target.value > max) {
        return
      }

      if (min !== undefined && e.target.value < min) {
        return
      }
    }

    e.target.value = withoutSpace ?
      e.target.value.trim() : e.target.value

    onChange(e)
  }

  const handlePaste = e => {
    let pastedText
    if (window.clipboardData && window.clipboardData.getData) {
      pastedText = window.clipboardData.getData("Text")
    } else if (e.clipboardData && e.clipboardData.getData) {
      pastedText = e.clipboardData.getData("text/plain")
    }

    if (type === "number" &&
      isNaN(pastedText)
    ) return e.preventDefault()

    if (type === "number" &&
      numberType === "int" &&
      Number(pastedText) % 1 !== 0
    ) return e.preventDefault()

    if (
      pastedText.length > maxLength &&
      maxLength !== -1
    ) return e.preventDefault()
  }

  const maxLengthReached = value.length === maxLength

  return (
    <div className={className}>
      <div className={classes["row"]}>
        <label className={classes["label"]}>
          {label}
        </label>
        {
          maxLength > 0 &&
          <div className={clsx(
            maxLengthReached && classes["max-reached"]
          )}>
            {value.length} / {maxLength}
          </div>
        }
      </div>
      <div className={clsx(classes["input-container"])}>
        {
          !!startIcon &&
          <div className={classes["start-icon"]}>
            {startIcon}
          </div>
        }
        <Form.Control
          value={value}
          onChange={handleChange}
          autoComplete="off"
          onPaste={handlePaste}
          maxLength={maxLength}
          onKeyDown={handleKeyDown}
          type={isPassword ?
            showPassword ? "text" : "password"
            : type
          }
          className={clsx(!!error && classes["input-error"],
            !!isPassword && classes["icon-padding"],
            !!initialTime && classes["timer-padding"],
            startIcon && classes["input-start-icon"]
          )}
          {...otherProps}
        />
        {
          isPassword &&
          <i
            onClick={() => setShowPassword(prev => !prev)}
            className={clsx(classes["is-password"],
              `fa fa-eye${showPassword ? "-slash" : ""}`,
            )}
          />
        }
        {
          !!initialTime &&
          <div className={classes["timer"]}>
            <CountDownTimer
              zeroPads={{ seconds: true }}
              show={{ minutes: true, seconds: true }}
              onTimeout={onTimeout}
              initialTime={initialTime}
              inSeconds
            />
          </div>
        }
      </div>
      {
        !!error &&
        <div className={classes["error"]}>
          {error}
        </div>
      }
    </div>
  )
}

export default TextField