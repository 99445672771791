import { stringify } from "query-string"
import { dhFoods } from "./api"

const endpoint = "/product"

export const getProducts = async queries => {
  const query = stringify(queries, {
    skipEmptyString: true,
    skipNull: true
  })

  const response = await dhFoods.get(`${endpoint}?${query}`)

  return response.data
}

export const getProduct = async (foodId) => {
  const response = await dhFoods.get(`${endpoint}/${foodId}`)

  return response.data
}

export const addProduct = async (data) => {
  const response = await dhFoods.post(`${endpoint}`, data)

  return response.data
}

export const editProduct = async (data, productId) => {
  const response = await dhFoods.patch(`${endpoint}/${productId}`, data)

  return response.data
}

export const deleteProduct = async (productId) => {
  const response = await dhFoods.delete(`${endpoint}/${productId}`)

  return response.data
}