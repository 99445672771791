import React from "react"

import classes from "./Pagination.module.scss"

const paginationButtons = 5

const Pagination = props => {
  const {
    onClick,
    dividedBy = 10,
    skip,
    disabled,
    value
  } = props

  const pagination = Math.ceil(value / dividedBy)
  const startNumber = pagination > paginationButtons ?
    pagination - ((skip / dividedBy) + 1) < paginationButtons ?
      pagination - paginationButtons + 1 : ((skip / dividedBy) + 1)
    : 1
  const paginationLimit = pagination > paginationButtons ?
    (pagination - startNumber) >= paginationButtons ?
      (startNumber + paginationButtons - 1) : pagination
    : pagination
  const currentPage = (skip / dividedBy) + 1
  const disablePrevButton = currentPage === 1
  const diableNextButton = currentPage === pagination
  const array = []
  for (let i = startNumber; i <= paginationLimit; i++)
    array.push(i)

  return (
    <div className={classes["pagination-buttons"]}>
      <button
        onClick={() => onClick(skip - dividedBy)}
        disabled={disablePrevButton}
        className={classes["button-left"]}
      >
        Previous
      </button>
      {array.map(a =>
        <button
          key={a}
          onClick={() =>
            currentPage !== a &&
            onClick((a - 1) * dividedBy)
          }
          disabled={disabled}
          className={skip === ((a - 1) * dividedBy) ?
            classes["button-active"] : classes["button"]
          }
        >
          {a}
        </button>
      )}
      <button
        className={classes["button-right"]}
        onClick={() => onClick(skip + dividedBy)}
        disabled={diableNextButton}
      >
        Next
      </button>
    </div>
  )
}

export default Pagination