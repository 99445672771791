import { dhFoodsStorage } from "./api"

const endpoint = "/image"

export const uploadImage = async (data, onUploadProgress, source = { token: null }) => {
  const response = await dhFoodsStorage.post(`${endpoint}`, data, {
    cancelToken: source.token,
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress
  })

  return response.data
}

export const uploadBillImage = async (data, contractId, onUploadProgress, source = { token: null }) => {
  const response = await dhFoodsStorage.post(`/access/${contractId}`, data, {
    cancelToken: source.token,
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress
  })

  return response.data
}

export const downloadBillImage = async url => {
  const response = await dhFoodsStorage.get(url, { responseType: "arraybuffer" })

  return response
}