import React from "react"
import ReactSelect from "react-select"
import AsyncSelect from "react-select/async"

import classes from "./Select.module.scss"

const Select = props => {
  const {
    error,
    label,
    className,
    async = false,
    ...otherProps
  } = props

  const Component = async ? AsyncSelect : ReactSelect

  return (
    <div className={className}>
      {
        !!label &&
        <label className={classes["label"]}>
          {label}
        </label>
      }
      <Component
        styles={{
          loadingIndicator: (props) => ({
            ...props,
            transform: "scale(0.5)"
          }),
          dropdownIndicator: (props) => ({
            ...props,
            padding: "0px 8px"
          }),
          control: (props, state) => ({
            ...props,
            fontSize: "14px",
            minHeight: "33px",
            boxShadow:
              state.isFocused ?
                !!error ?
                  "0 0 0 0.2rem rgb(220 53 69 / 25%)" :
                  "0 0 0 0.2rem rgb(0, 123, 255, 0.25)"
                : "",
            borderColor: !!error ?
              "#db3c36" :
              state.isFocused ?
                "#80bdff" : "rgba(0,0,0,0.1)",
            borderWidth: "1px",
            "&:hover": {
              border: !!error ?
              "1px solid #db3c36":
              "1px solid rgba(0,0,0,0.1)"
            }
          })
        }}
        {...otherProps}
      />
      {
        !!error &&
        <div className={classes["error"]}>
          {error}
        </div>
      }
    </div>
  )
}

export default Select