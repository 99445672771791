import React from "react"
import { useSelector } from "react-redux"
import { Redirect } from "react-router-dom"

import PageLoader from "./PageLoader"

const ProtectedRoute = props => {
  const { children, allowedRoles } = props

  const { role } = useSelector(state => state.userAccountInfo)

  if (!role) return <PageLoader />

  if (!allowedRoles.includes(role)) {
    return <Redirect to="/404" />
  }

  return children
}

export default ProtectedRoute