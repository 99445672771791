import React, { useState, useEffect } from "react"

import { downloadBillImage } from "services"

const ImagesWithAuth = (props) => {
  const {
    src,
    imagePlaceholder,
    ...otherProps
  } = props

  const [url, setUrl] = useState("")

  const getImage = async () => {
    if (!src) return

    try {
      const response = await downloadBillImage(src)

      const blob = new Blob([response.data], { type: response.headers["content-type"] })

      setUrl(URL.createObjectURL(blob))
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getImage()
  }, [src])

  return (
    <img
      src={url ? url : imagePlaceholder}
      alt=""
      {...otherProps}
    />
  )
}

export default ImagesWithAuth