import { stringify } from "query-string"

import { dhFoodsAnalytic } from "./api"

const endpoint = "/report"

export const getReportBills = async queries => {
  const query = stringify(queries, {
    skipEmptyString: true,
    skipNull: true
  })

  const response = await dhFoodsAnalytic.get(`${endpoint}/sale?${query}`)

  return response.data
}
