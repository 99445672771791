import React, { Fragment } from "react"

import classes from "./DpHeader.module.scss"

const DpHeader = props => {
  const {
    shownDate,
    rangeSelect,
    selectedRange,
    timePicker,
    selectedDate,
    setContent,
    showTimeSelect,
    rangeTimePicker,
    timeRange
  } = props

  const timeFormat = timePicker ? {
    hour: "numeric",
    minute: "numeric"
  } : showTimeSelect ? {
    month: "short",
    day: "2-digit",
    weekday: "short",
    hour: "numeric",
    minute: "numeric"
  } : {
    month: "short",
    day: "2-digit",
    weekday: "short"
  }

  return (
    <div className={classes["header"]}>
      {rangeTimePicker ?
        <Fragment>
          <span className={classes["start-time"]}
          >
            Start Time From:{" "}
            {timeRange[0].hours}:{timeRange[0].minutes}
            <br />
            {
              !!timeRange[1] &&
              <Fragment>
                To:{" "}
                {timeRange[1].hours}:{timeRange[1].minutes}
              </Fragment>
            }
          </span>
        </Fragment>
        :
        rangeSelect ?
          <Fragment>
            <span
              className={classes["full-year"]}
              onClick={() => setContent("years")}
            >
              {shownDate.getFullYear()}
            </span>
            <div className={classes["selected-range"]}>
              {selectedRange[0]?.toLocaleString(undefined, timeFormat)}
              {
                selectedRange.length === 2 &&
                <span>{" "}~{" "}</span>
              }
              {selectedRange[1]?.toLocaleString(undefined, timeFormat)}
            </div>
          </Fragment> :
          <Fragment>
            <div>
              {
                !timePicker &&
                <span
                  className={classes["full-year"]}
                  onClick={() => setContent("years")}
                >
                  {selectedDate.getFullYear()}
                </span>
              }
            </div>
            <div className={classes["selected-date"]}>
              {selectedDate.toLocaleString(undefined, timeFormat)}
            </div>
          </Fragment>
      }
    </div>
  )
}

export default DpHeader