import React, { useRef, useState } from "react"
import { Modal } from "react-bootstrap"
import Cropper from "react-cropper"

import Button from "../Button"
import classes from "./CropModal.module.scss"

const CropModal = props => {
  const {
    src,
    openModal,
    setOpenModal,
    handleUpload
  } = props

  const [cropping, setCropping] = useState(false)

  const cropperRef = useRef(null)

  const getCropData = async () => {
    const cropper = cropperRef.current.cropper

    const croppedWidth = cropper.getData().width

    const canvas = await cropper.getCroppedCanvas({
      width: croppedWidth > 1280 ? 1280 : croppedWidth
    })

    const newCanvas = document.createElement("canvas")

    newCanvas.width = canvas.width
    newCanvas.height = canvas.height

    const ctx = newCanvas.getContext("2d")
    ctx.fillStyle = "#ffffff"
    ctx.fillRect(0, 0, canvas.width, canvas.height)
    ctx.drawImage(canvas, 0, 0,
      canvas.width, canvas.height, 0, 0,
      newCanvas.width, newCanvas.height
    )

    newCanvas.toBlob((blob) => {
      handleUpload({
        uploaded: false,
        blob,
        url: URL.createObjectURL(blob)
      })

      setOpenModal(false)
    }, "image/jpeg")
  }

  const handleConfirm = () => {
    setCropping(true)
    getCropData()
  }

  const handleExited = () => {
    setCropping(false)
    // setSrc(null)
  }

  return (
    <Modal
      show={openModal}
      onHide={() => setOpenModal(false)}
      onExited={handleExited}
      backdrop="static"
      centered
    >
      <div className={classes["container"]}>
        <h4 className={classes["title"]}>
          Crop your image
        </h4>
        {
          src &&
          <Cropper
            src={src}
            style={{ height: 400, width: "100%" }}
            viewMode={1}
            zoomable={false}
            ref={cropperRef}
          />
        }
        <div className={classes["footer"]}>
          <Button
            color="danger"
            onClick={() => setOpenModal(false)}
          >
            Cancel
          </Button>
          <Button
            disabled={cropping}
            onClick={handleConfirm}
          >
            {cropping ? "Cropping..." : "Confirm"}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default CropModal