import * as types from "../types/systemSettings"
import { getSettings } from "services"

export const fetchSystemSettings = () => (
  async (dispatch) => {
    try {
      const response = await getSettings()

      dispatch({
        type: types.FETCH_SYSTEM_SETTINGS,
        data: { ...response }
      })
    } catch (err) {
      console.log(err)
    }
  }
)

export const updateSystemSettings = (newSystemSettings) => ({
  type: types.UPDATE_SYSTEM_SETTINGS,
  newSystemSettings
})