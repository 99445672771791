import React, { Fragment } from "react"
import clsx from "clsx"

import classes from "./DpMonths.module.scss"

const monthsName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

const DpMonths = props => {
  const {
    minDate,
    maxDate,
    shownDate,
    setShownDate,
    setContent
  } = props

  const handleClick = monthIndex => {
    const newDate = new Date(shownDate)
    newDate.setMonth(monthIndex)
    setShownDate(newDate)
    setContent("days")
  }

  const handleClose = () => {
    setContent("days")
  }

  return (
    <Fragment>
      <button
        onClick={handleClose}
        className={classes["close-button"]}
      >
        <i className="fa fa-close" />
      </button>
      <div className={classes["month-container"]}>
        <div className={classes["month"]}>
          {monthsName.map((monthName, i) => {
            const month = new Date(shownDate)
            month.setMonth(i)
            month.setDate(1)
            month.setHours(0, 0, 0, 0)
            const max = new Date(maxDate)
            max.setDate(1)
            max.setHours(0, 0, 0, 0)
            const min = new Date(minDate)
            min.setDate(1)
            min.setHours(0, 0, 0, 0)

            const isDisabled =
              Number(month) > Number(max) ||
              Number(month) < Number(min)

            return (
              <button
                key={monthName}
                disabled={isDisabled}
                onClick={() => handleClick(i)}
                className={clsx(classes["month-name"],
                  monthsName[shownDate.getMonth()] === monthName &&
                  classes["active"]
                )}
              >
                {monthName}
              </button>
            )
          })}
        </div>
      </div>
    </Fragment>
  )
}

export default DpMonths