const resizeImage = (file, limit = 1280) => (
  new window.Promise(resolve => {
    const img = new Image()
    img.src = URL.createObjectURL(file)

    img.onload = () => {
      const canvas = document.createElement("canvas")

      if (img.width > limit) {
        const aspectRatio = img.height / img.width

        canvas.width = limit
        canvas.height = (limit * aspectRatio)
      } else {
        canvas.width = img.width
        canvas.height = img.height
      }

      const ctx = canvas.getContext("2d");

      ctx.fillStyle = "#ffffff"
      ctx.fillRect(0, 0, canvas.width, canvas.height)

      ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height)

      canvas.toBlob((blob) => {
        resolve({
          url: URL.createObjectURL(blob),
          blob
        })
      }, "image/jpeg")
    }
  })
)

export default resizeImage