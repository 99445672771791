import React from "react"
import { Modal } from "react-bootstrap"

import Button from "./Button"
import classes from "./ConfirmationModal.module.scss"

const ConfirmationModal = props => {
  const {
    show,
    title,
    onHide,
    onExited,
    onConfirm,
    sending
  } = props

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      onExited={onExited}
      centered
    >
      <div className={classes["container"]}>
        <h4 className={classes["title"]}>
          {title}
        </h4>
        <div className={classes["footer"]}>
          <Button
            color="success"
            loading={sending}
            onClick={onConfirm}
          >
            Yes
          </Button>
          <Button
            color="danger"
            disabled={sending}
            onClick={onHide}
          >
            No
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmationModal