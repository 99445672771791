import React, { useEffect, useRef, useState } from "react"

const CountDownTimer = props => {
  const {
    initialTime,
    zeroPads,
    show,
    onTimeout,
    inSeconds,
    ...otherProps
  } = props

  const [time, setTime] = useState(null)

  const initialRef = inSeconds ?
    initialTime :
    Math.round((new Date(initialTime).getTime() - new Date().getTime()) / 1000)

  const timeDistance = useRef(initialRef)

  const getTimeString = timer => {
    const days = parseInt(timer / (60 * 60 * 24), 10)
    let hours = parseInt((timer % (60 * 60 * 24)) / (60 * 60), 10)
    let minutes = parseInt((timer % (60 * 60)) / (60), 10)
    let seconds = parseInt(timer % 60, 10)

    hours = zeroPads.hours ?
      hours < 10 ? "0" + hours : hours : hours
    minutes = zeroPads.minutes ?
      minutes < 10 ? "0" + minutes : minutes : minutes
    seconds = zeroPads.seconds ?
      seconds < 10 ? "0" + seconds : seconds : seconds

    let string = ""
    if (show.days) string += `${days} Day${days > 1 ? "s" : ""} ${days > 1 ? "and " : ""}`
    if (show.hours) string += `${hours} : `
    if (show.minutes) string += `${minutes} : `
    if (show.seconds) string += `${seconds}`

    timeDistance.current--
    setTime(string)
  }

  const countDown = () => {
    getTimeString(timeDistance.current)

    const interval = setInterval(() => {
      getTimeString(timeDistance.current)

      if (timeDistance.current < 0) {
        clearInterval(interval)
        onTimeout()
      }
    }, 1000)

    return interval
  }

  useEffect(() => {
    if (initialTime <= 0) return

    const interval = countDown()
    return () => clearInterval(interval)
  }, [])

  return (
    <div {...otherProps}>
      {
        time !== null &&
        <span>{time}</span>
      }
    </div>
  )
}

CountDownTimer.defaultProps = {
  style: null,
  initialTime: null,
  onTimeout: () => { },
  zeroPads: {
    seconds: false,
    minutes: false,
    hours: false,
    days: false
  },
  show: {
    seconds: true,
    minutes: true,
    hours: false,
    days: false
  },
  inSeconds: true
}

export default CountDownTimer