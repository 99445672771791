import React from "react"

import classes from "./DpBottomButtons.module.scss"

const DpBottomButtons = props => {
  const {
    rangeTimePicker,
    timeRangeStep,
    timeRange,
    setTimeRange,
    selectedTime,
    setSelectedTime
  } = props

  const handleDecreaseMinute = () => {
    const minuteNumber = Number(selectedTime.minutes)
    let newMinutes = minuteNumber - 1
    newMinutes = newMinutes < 0 ? 59 : newMinutes
    if (newMinutes < 10) newMinutes = `0${newMinutes}`

    setSelectedTime(prev => ({
      ...prev,
      minutes: newMinutes
    }))
  }

  const handleDecreaseHour = () => {
    const hourNumber = Number(selectedTime.hours)
    let newHour = hourNumber - 1
    newHour = newHour < 0 ? 23 : newHour
    if (newHour < 10) newHour = `0${newHour}`

    setSelectedTime(prev => ({
      ...prev,
      hours: newHour
    }))
  }

  const handleDecreaseHourRange = () => {
    const index = timeRangeStep === "start" ? 0 : 1

    const hourNumber = Number(timeRange[index].hours)
    let newHour = hourNumber - 1
    newHour = newHour < 0 ? 23 : newHour
    if (newHour < 10) newHour = `0${newHour}`

    setTimeRange(prev => {
      const array = [...prev]
      array[index].hours = newHour
      return array
    })
  }

  const handleDecreaseMinuteRange = () => {
    const index = timeRangeStep === "start" ? 0 : 1

    const minuteNumber = Number(timeRange[index].minutes)
    const hourNumber = Number(timeRange[index].hours)

    let newMinutes = minuteNumber - 1
    newMinutes = hourNumber === 24 ? 0 :
      newMinutes < 0 ? 59 : newMinutes

    if (newMinutes < 10) newMinutes = `0${newMinutes}`

    setTimeRange(prev => {
      const array = [...prev]
      array[index].minutes = newMinutes
      return array
    })
  }

  return (
    <div className={classes["row"]}>
      <div
        onClick={rangeTimePicker ?
          handleDecreaseHourRange :
          handleDecreaseHour
        }
        className={classes["button"]}
      >
        <i className="fa fa-chevron-down" />
      </div>
      <div
        onClick={rangeTimePicker ?
          handleDecreaseMinuteRange :
          handleDecreaseMinute
        }
        className={classes["button"]}
      >
        <i className="fa fa-chevron-down" />
      </div>
    </div>
  )
}

export default DpBottomButtons