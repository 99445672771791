import axios from "axios"

const handleRequest = req => {
  try {
    if (localStorage.at) {
      req.headers.common["X-Access-Token"] =
        `Bearer ${localStorage.at}`
    }

    req.headers.common["X-Service-Client-Name"] =
        "subscribe"
  } catch (err) {
    console.log(err)
  }

  return req
}

const dhFoodsAuth = axios.create({
  baseURL: process.env.REACT_APP_API_AUTH_URL
})
dhFoodsAuth.interceptors.request.use(handleRequest)
dhFoodsAuth.interceptors.response.use(
  response => response
)

const dhFoodsAnalytic = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL.replace("/subscribe/", "/analytic/")
})
dhFoodsAnalytic.interceptors.request.use(handleRequest)
dhFoodsAnalytic.interceptors.response.use(
  response => response
)

const dhFoods = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
})
dhFoods.interceptors.request.use(handleRequest)
dhFoods.interceptors.response.use(
  response => response
)

const dhFoodsStorage = axios.create({
  baseURL: process.env.REACT_APP_API_STORAGE_URL
})
dhFoodsStorage.interceptors.request.use(handleRequest)
dhFoodsStorage.interceptors.response.use(
  response => response
)

export { 
  dhFoods, 
  dhFoodsStorage,
   dhFoodsAuth,
   dhFoodsAnalytic
  }