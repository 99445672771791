import React from "react"
import { Redirect, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"

const RedirectToHomePage = () => {
  const { role } = useSelector(state => state.userAccountInfo)

  const { search } = useLocation()

  if (role === "subscribeAdmin") {
    return <Redirect to="/products" />
  }

  const plan = new URLSearchParams(search).get("plan")

  if (role === "subscribeUser") {
    return <Redirect to={`/wizard${!!plan ? `?plan=${plan}` : ""}`} />
  }
}

export default RedirectToHomePage