import React, { Fragment } from "react"
import { Link } from "react-router-dom"

import classes from "./Breadcrumb.module.scss"

const Breadcrumb = props => {
  const { items } = props

  return (
    <div className={classes["container"]}>
      <Link to="/">Home</Link>
      <span>/</span>
      {items.map((item, i) =>
        <Fragment key={i}>
          <span            
            className={classes["item"]}
          >
            {item}
          </span>
          {
            items.length - 1 !== i &&
            <span>/</span>
          }
        </Fragment>
      )}
    </div>
  )
}

export default Breadcrumb