import React from "react"

import classes from "./DpTopButtons.module.scss"

const DpTopButtons = props => {
  const {
    rangeTimePicker,
    timeRangeStep,
    timeRange,
    setTimeRange,
    selectedTime,
    setSelectedTime
  } = props

  const handleIncreaseHour = () => {
    const hourNumber = Number(selectedTime.hours)
    let newHour = hourNumber + 1
    newHour = newHour === 24 ? 0 : newHour
    if (newHour < 10) newHour = `0${newHour}`

    setSelectedTime(prev => ({
      ...prev,
      hours: newHour
    }))
  }

  const handleIncreaseMinute = () => {
    const minuteNumber = Number(selectedTime.minutes)
    let newMinutes = minuteNumber + 1
    newMinutes = newMinutes === 60 ? 0 : newMinutes
    if (newMinutes < 10) newMinutes = `0${newMinutes}`

    setSelectedTime(prev => ({
      ...prev,
      minutes: newMinutes
    }))
  }

  const handleIncreaseHourRange = () => {
    const index = timeRangeStep === "start" ? 0 : 1
    const hourNumber = Number(timeRange[index].hours)
    let newHour = hourNumber + 1
    newHour = newHour === 25 ? 0 : newHour

    setTimeRange(prev => {
      const array = [...prev]
      array[index].hours = newHour < 10 ? `0${newHour}` : newHour

      if (newHour === 24) array[index].minutes = "00"

      return array
    })
  }

  const handleIncreaseMinuteRange = () => {
    const index = timeRangeStep === "start" ? 0 : 1
    const minuteNumber = Number(timeRange[index].minutes)
    const hourNumber = Number(timeRange[index].hours)

    let newMinutes = minuteNumber + 1
    newMinutes = hourNumber === 24 ? 0 :
      newMinutes === 60 ? 0 : newMinutes
      
    if (newMinutes < 10) newMinutes = `0${newMinutes}`

    setTimeRange(prev => {
      const array = [...prev]
      array[index].minutes = newMinutes
      return array
    })
  }

  return (
    <div className={classes["row"]}>
      <div
        onClick={rangeTimePicker ?
          handleIncreaseHourRange :
          handleIncreaseHour
        }
        className={classes["button"]}
      >
        <i className="fa fa-chevron-up" />{" "}
      </div>
      <div
        onClick={rangeTimePicker ?
          handleIncreaseMinuteRange :
          handleIncreaseMinute
        }
        className={classes["button"]}
      >
        <i className="fa fa-chevron-up" />
      </div>
    </div>
  )
}

export default DpTopButtons