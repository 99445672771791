import React from "react"

const Signin = React.lazy(() => import('../pages/signin'))
const PageNotFound = React.lazy(() => import('../pages/404'))

const publicRoutes = [{
  path: "/",
  component: Signin
}, {
  path: "*",
  component: PageNotFound
}]

export default publicRoutes