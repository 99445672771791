import { stringify } from "query-string"
import { dhFoods } from "./api"

const endpoint = "/bankTransactionNote"

export const sendBankTransactionNote = async data => {
  const response = await dhFoods.post(`${endpoint}`, data)

  return response.data
}

export const saveBankTransactionNote = async (bankTransactionNoteId, data) => {
  const response = await dhFoods.patch(`${endpoint}/${bankTransactionNoteId}`, data)

  return response.data
}

export const deleteBankTransactionNote = async (bankTransactionNoteId) => {
  const response = await dhFoods.delete(`${endpoint}/${bankTransactionNoteId}`)

  return response.data
}

export const getBankTransactionNotes = async queries => {
  const query = stringify(queries, {
    skipEmptyString: true,
    skipNull: true
  })

  const response = await dhFoods.get(`${endpoint}?${query}`)

  return response.data
}

export const getBankTransactionNote = async (bankTransactionNoteId) => {
  const response = await dhFoods.get(`${endpoint}/${bankTransactionNoteId}`)

  return response.data
}