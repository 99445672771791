import React from "react"
import clsx from "clsx"

import classes from "./TopNavigation.module.scss"

const TopNavigation = props => {
  const { setOpenSidebar } = props

  return (
    <div className={classes["container"]}>
      <i
        onClick={() => setOpenSidebar(prev => !prev)}
        className={clsx("fa fa-bars", classes["menu"])}
      />
    </div>
  )
}

export default TopNavigation