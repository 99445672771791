import React, { Fragment, Suspense } from "react"
import ReactDOM from "react-dom"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom"
import isJwt from "validator/lib/isJWT"
import { PersistGate } from "redux-persist/integration/react"
import { Provider } from "react-redux"
import { ToastContainer } from "react-toastify"

import { store, persistor } from "@redux"
import * as serviceWorker from "./serviceWorker"
import {
  ProtectedRoute,
  RedirectToHomePage,
  ErrorBoundary,
  PageLoader
} from "components"
import Container from "./containers"
import { privateRoutes, publicRoutes } from "routes"

// Css
import { logout } from "utils"
import "react-image-lightbox/style.css"
import "react-toastify/dist/ReactToastify.css"
import "cropperjs/dist/cropper.css"
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./index.scss"

const App = () => {

  if (!localStorage.b) {
    localStorage.b = "true"

    logout()

    window.location.href = "/"

    return null
  }

  return (
    <Fragment>
      <Provider store={store}>
        <ErrorBoundary>
          <PersistGate loading={null} persistor={persistor}>
            <Suspense fallback={<PageLoader />}>
              <Router>
                <Switch>
                  <Route
                    path={privateRoutes.map(privateRoute => privateRoute.path)}
                    exact
                  >
                    <Container>
                      <Switch>
                        {privateRoutes.map(privateRoute =>
                          <Route
                            exact
                            key={privateRoute.path}
                            path={privateRoute.path}
                            render={() => isJwt(localStorage.at || "") ?
                              <ProtectedRoute
                                allowedRoles={privateRoute.allowedRoles}
                              >
                                <privateRoute.component />
                              </ProtectedRoute> :
                              <Redirect to="/" />
                            }
                          />
                        )}
                      </Switch>
                    </Container>
                  </Route>
                  <Route
                    path={publicRoutes.map(publicRoute => publicRoute.path)}
                    exact
                  >
                    <Switch>
                      {publicRoutes.map(publicRoute =>
                        <Route
                          key={publicRoute.path}
                          path={publicRoute.path}
                          exact
                          render={() => publicRoute.path === "*" ?
                            <publicRoute.component /> :
                            isJwt(localStorage.at || "") ?
                              <RedirectToHomePage /> :
                              <publicRoute.component />
                          }
                        />
                      )}
                    </Switch>
                  </Route>
                </Switch>
              </Router>
            </Suspense>
          </PersistGate>
        </ErrorBoundary>
      </Provider>
      <ToastContainer />
    </Fragment>
  )
}

ReactDOM.render(
  <App />,
  document.getElementById("root")
)

serviceWorker.unregister()