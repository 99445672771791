import React from "react"
import clsx from "clsx"

import classes from "./Checkbox.module.scss"

const Checkbox = props => {
  const {
    className = "",
    checked = false,
    disabled = false,
    onChange = () => { },
    ...otherProps
  } = props

  return (
    <div
      className={clsx(classes["checkbox-container"], className)}
      onClick={e => !disabled && onChange(!checked, e)}
      {...otherProps}
    >
      <input
        className={classes["hidden-checkbox"]}
      />
      <div
        className={clsx(classes["checkbox"],
          checked && classes["checkbox-checked"],
          disabled && classes["checkbox-disabled"],
          checked && disabled && classes["checkbox-checked-disabled"]
        )}
      >
        {checked && <i
          className={clsx("fa fa-check", classes["check"])}
        />}
      </div>
    </div>
  )
}

export default Checkbox
