import React, { useLayoutEffect } from "react"
import clsx from "clsx"

import classes from "./DpYears.module.scss"

const DpYears = props => {
  const {
    minDate,
    maxDate,
    shownDate,
    setShownDate,
    setContent
  } = props

  const handleClick = year => {
    const newDate = new Date(shownDate)
    newDate.setFullYear(year)
    setShownDate(newDate)
    setContent("months")
  }

  const handleClose = () => {
    setContent("days")
  }

  const years = []
  for (let i = 2100; i >= 1900; i--) years.push(i)

  useLayoutEffect(() => {
    const element = document.getElementById("active-year")
    element.scrollIntoView()
  }, [])

  return (
    <div className={classes["container"]}>
      <div className={classes["years"]}>
        <button
          onClick={handleClose}
          className={classes["close-button"]}
        >
          <i className="fa fa-close" />
        </button>
        <div className={classes["years-container"]}>
          {years.map(year => {
            const isActive = shownDate.getFullYear() === year

            const isDisabled =
            year > maxDate?.getFullYear() ||
            year < minDate?.getFullYear()
            
            return (
              <button
                key={year}
                disabled={isDisabled}
                id={isActive ? "active-year" : ""}
                onClick={() => handleClick(year)}
                className={clsx(classes["year"],
                  isActive && classes["active"]
                )}
              >
                {year}
              </button>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default DpYears