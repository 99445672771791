import jwtDecode from "jwt-decode"

import * as types from "../types/userAccountInfo"
import { getUserAccountInfo } from "services"

export const fetchUserAccountInfo = () => (
  async (dispatch) => {
    const response = await getUserAccountInfo()

    const decodedToken = jwtDecode(localStorage.at)

    dispatch({
      type: types.FETCH_USER_INFO,
      userAccountInfo: {
        ...response,
        role: decodedToken.role
      }
    })
  }
)

export const updateUserAccountInfo = userAccountInfo => ({
  type: types.UPDATE_USER_INFO,
  userAccountInfo
})

export const deleteUserAccountInfo = () => ({
  type: types.DELETE_USER_INFO
})