import React, { useState, useRef, Fragment } from "react"
import { toast } from "react-toastify"
import clsx from "clsx"

import { resizeImage } from "utils"
import CropModal from "./CropModal"
import { uploadBillImage } from "services"
import classes from "./index.module.scss"
import { ImagesWithAuth } from "components"

const Uploader = props => {
  const {
    contractId,
    enableCrop = true,
    onAdded,
    imagesWithAuth = false,
    value,
    label,
    error,
    aspectRatio
  } = props

  const [src, setSrc] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [progressBar, setProgressBar] = useState(0)
  const [uploading, setUploading] = useState(false)

  const fileInputRef = useRef(null)

  const handleClick = () => {
    const uploader = fileInputRef.current
    uploader.value = null
    uploader.click()
  }

  const handleFiles = async (e) => {
    e.preventDefault()

    const file = e.target.files[0]

    if (!file) return

    const validExts = ["jpg", "jpeg", "png", "tiff", "webp"]
    if (!validExts.includes(file.type.split("/")[1])) {
      toast.error("File must be jpg jpeg png tiff")
      return
    }

    setSrc(URL.createObjectURL(file))

    if (enableCrop) return setOpenModal(true)

    const resizedImage = await resizeImage(file)

    handleUpload(resizedImage)

  }

  const handleProgress = progressEvent => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    )

    setProgressBar(percentCompleted)
  }

  const handleUpload = async image => {
    setUploading(true)
    setProgressBar(0)

    try {
      const form = new FormData()
      form.append("qqfile", image.blob, "image.jpg")

      const { url } =
        await uploadBillImage(form, contractId, handleProgress)

      onAdded(url)

      setUploading(false)
    } catch (err) {
      console.log(err)
      toast.error("Uploading image failed")
    }
  }

  return (
    <Fragment>
      <div className={classes["container"]}>
        <label className={classes["label"]}>
          {label}
        </label>
        <div
          onClick={handleClick}
          className={clsx(classes["content"],
            !!error && classes["error-content"]
          )}
        >
          {uploading ?
            <div className={classes["image-container"]}>
              <img
                className={classes["image"]}
                src={src}
                alt=""
              />
              <div className={classes["overlay"]}>
                {progressBar}%
              </div>
            </div>
            :
            !!value ?
              <div className={classes["image-container"]}>
                {imagesWithAuth ?
                  <ImagesWithAuth
                    src={`${value}?width=480&quality=90`}
                    className={classes["image"]}
                    alt=""
                  /> :
                  <img
                    className={classes["image"]}
                    src={`${value}?width=480&quality=90`}
                    alt=""
                  />
                }
              </div>
              :
              <div className={classes["placeholder"]}>
                {label}
              </div>
          }
        </div>
        {
          !!error &&
          <div className={classes["error"]}>
            {error}
          </div>
        }
      </div>
      <input
        hidden
        type="file"
        ref={fileInputRef}
        onChange={handleFiles}
        accept="image/jpeg, image/png, image/tiff"
      />
      {
        enableCrop &&
        <CropModal
          src={src}
          setSrc={setSrc}
          openModal={openModal}
          setOpenModal={setOpenModal}
          handleUpload={handleUpload}
          aspectRatio={aspectRatio}
        />
      }
    </Fragment>
  )
}

export default Uploader