import React, { useLayoutEffect } from "react"
import DpBottomButtons from "./DpBottomButtons"
import DpTimeInputs from "./DpTimeInputs"
import DpTopButtons from "./DpTopButtons"

import classes from "./index.module.scss"

const DpTimePicker = props => {
  const {
    timeRange,
    setTimeRange,
    rangeTimePicker,
    timeRangeStep,
    setTimeRangeStep,
    selectedDate,
    selectedTime,
    setContent,
    timePicker,
    setSelectedTime
  } = props

  useLayoutEffect(() => {
    if (rangeTimePicker) return

    let initialHour = selectedDate.getHours()
    let initialMinutes = selectedDate.getMinutes()

    initialMinutes = initialMinutes < 10 ?
      `0${initialMinutes}` : initialMinutes
    initialHour = initialHour < 10 ?
      `0${initialHour}` : initialHour
    
    setSelectedTime({
      hours: initialHour,
      minutes: initialMinutes
    })
  }, [selectedDate])

  const showChevron = rangeTimePicker ?
    timeRangeStep === "end" :
    !timePicker
  return (
    <div className={classes["container"]}>
      {
        showChevron &&
        <button
          onClick={() => timeRangeStep === "end" ?
            setTimeRangeStep("start") :
            setContent("days")
          }
          className={classes["close-button"]}
        >
          <i className="fa fa-chevron-left" />
        </button>
      }
      <div className={classes["wrapper"]}>
        <DpTopButtons
          rangeTimePicker={rangeTimePicker}
          timeRangeStep={timeRangeStep}
          timeRange={timeRange}
          setTimeRange={setTimeRange}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
        />
        <DpTimeInputs
          rangeTimePicker={rangeTimePicker}
          timeRangeStep={timeRangeStep}
          timeRange={timeRange}
          setTimeRange={setTimeRange}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
        />
        <DpBottomButtons
          rangeTimePicker={rangeTimePicker}
          timeRangeStep={timeRangeStep}
          timeRange={timeRange}
          setTimeRange={setTimeRange}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
        />
        <div className={classes["dots"]}>
          :
        </div>
      </div>
    </div >
  )
}

export default DpTimePicker