import * as types from "../types/userAccountInfo"

const initialState = {}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_USER_INFO:
      return { ...action.userAccountInfo }
    case types.UPDATE_USER_INFO:
      return { ...state.userData, ...action.userAccountInfo }
    case types.DELETE_USER_INFO:
      return { ...initialState }
    default:
      return state
  }
}

export default userReducer