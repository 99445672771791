import React, { Fragment } from "react"
import clsx from "clsx"

import classes from "./DpDates.module.scss"
import DpMonthHeader from "./DpMonthHeader"

const DpDates = props => {
  const {
    minDate,
    maxDate,
    rangeSelect,
    selectedRange,
    setSelectedRange,
    showTimeSelect,
    selectedDate,
    setSelectedDate,
    shownDate,
    setShownDate,
    setContent
  } = props

  const handleSelectDate = date => {
    if (showTimeSelect) {
      date.setHours(selectedDate.getHours(),
        selectedDate.getMinutes(),
        selectedDate.getSeconds(),
        0
      )
      setContent("time")
    }
    setSelectedDate(date)
  }

  const handleSelectRange = date => {
    let newRange = [...selectedRange]
    if (newRange.length === 2) {
      newRange = [date]
    } else {
      if (!!newRange[0]) {
        if (Number(newRange[0]) > Number(date)) {
          newRange[1] = newRange[0]
          newRange[0] = date
        } else {
          newRange[1] = date
        }
      } else {
        newRange[0] = date
      }
    }

    setSelectedRange([...newRange])
  }

  const handleClick = (date, isEmpty) => {
    rangeSelect ?
      handleSelectRange(date) :
      !isEmpty && handleSelectDate(date)
  }

  const year = shownDate.getFullYear()
  const month = shownDate.getMonth()
  const daysInMonth = new Date(year, month + 1, 0).getDate()
  const firstDayOfMonth = new Date(year, month, 1).getDay()
  const dayTime = 24 * 60 * 60 * 1000
  const array = []
  for (let i = 1; i <= daysInMonth; i++) {
    const date = new Date(shownDate.getFullYear(),
      shownDate.getMonth(), i
    )

    const endInterval = [
      new Date(selectedRange[1] || 0).setHours(0, 0, 0),
      new Date(selectedRange[1] || 0).setHours(23, 59, 59)
    ]
    const isEndRange =
      Number(endInterval[0]) <= Number(date) &&
      Number(endInterval[1]) >= Number(date)

    const isStartRange = Number(date) === Number(selectedRange[0])

    const isInRange =
      rangeSelect && !isEndRange && !isStartRange &&
      Number(date) <= Number(selectedRange[1]) &&
      Number(date) >= Number(selectedRange[0])

    const isDisabled =
      Number(date) >= Number(maxDate) ||
      Number(date) <= (Number(minDate) - dayTime)

    const isToday =
      !rangeSelect &&
      Number(selectedDate) >= Number(date) &&
      Number(selectedDate) < (Number(date) + dayTime)

    array[firstDayOfMonth + i - 1] = {
      isDisabled,
      isStartRange,
      isInRange,
      isToday,
      isEndRange,
      date
    }
  }
  for (let i = 0; i < array.length; i++) {
    if (!array[i]) array[i] = { date: null }
  }

  return (
    <Fragment>
      <DpMonthHeader
        minDate={minDate}
        maxDate={maxDate}
        setContent={setContent}
        shownDate={shownDate}
        setShownDate={setShownDate}
      />
      <div className={classes["days"]}>
        {array.map((item, i) => {
          const {
            date,
            isDisabled,
            isToday,
            isStartRange,
            isEndRange,
            isInRange
          } = item
          const isEmpty = !date

          return (
            <button
              key={i}
              onClick={() => !isDisabled && handleClick(date, isEmpty)}
              className={clsx(classes["day"],
                isToday && classes["active"],
                (isInRange || isStartRange || isEndRange) && classes["active"],
                isEndRange && classes["active"],
                isEmpty && classes["hide"],
                isDisabled && classes["disabled"]
              )}
            >
              {isEmpty ? "" : date.getDate()}
            </button>
          )
        })}
      </div>
    </Fragment >
  )
}

export default DpDates